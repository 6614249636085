export const MONTH_NAMES = 'January February March April May June July August September October November December'.split(' ')

export const getMonth = (index, offset = 0) => {
  const monthIndex = index + offset
  const monthsCount = MONTH_NAMES.length
  const monthsModulo = ((monthIndex%monthsCount)+monthsCount)%monthsCount
  return MONTH_NAMES[monthsModulo]
}

export const MOOD_POSITIVES = [
  "Assignment progress",
  "Social activities outside work",
  "Engaging with the local culture",
  "Colleagues within the PO",
  "Establishing a routine",
  "Holidays / travel",
  "Time off work",
  "Other"
]

export const MOOD_NEGATIVES = [
  "Progress (or lack thereof)",
  "Isolation",
  "Feeling apart from the local culture",
  "Colleagues within the PO",
  "Too much work",
  "Too little work",
  "Unrealistic assignment goals",
  "Other"
]

export const TEST_DATA_MOODS = [
  [0.5431034482758621,0.3620689655172414,0.3793103448275862,0.05172413793103448,-0.23275862068965517,-0.43103448275862066,0.5172413793103449,0.3706896551724138,0.4051724137931034],
  [0.8706896551724138,0.45689655172413796,0.3448275862068966,-0.12931034482758622,-0.1896551724137931,-0.28448275862068967,0.8103448275862069,0.31896551724137934,0.5862068965517241],
  [0.5344827586206896,0.5517241379310345,0.19827586206896552,-0.5431034482758621,-0.5775862068965517,0.4827586206896552,0.41379310344827586],
  [0.4827586206896552,0.39655172413793105,0.35344827586206895,0.1724137931034483,-0.3620689655172414,0.017241379310344827,0.5086206896551724,0.1810344827586207,0.29310344827586204],
  [0.5258620689655172,0.5086206896551724,0.33620689655172414,-0.008620689655172414,-0.4482758620689655,0.11206896551724138,0.3793103448275862,0.2413793103448276,0.5517241379310345],
  [0.5,0.39655172413793105,0.27586206896551724,0.02586206896551724,-0.45689655172413796,0.5344827586206896,0.25,0.29310344827586204,0.5862068965517241],
  [0.603448275862069,0.3793103448275862,0.3793103448275862,-0.5689655172413793,-0.31896551724137934,0.5086206896551724,0.3448275862068966,0.41379310344827586,0.2413793103448276,0.1206896551724138,0.33620689655172414,0.21551724137931033],
  [0.3103448275862069,0.4051724137931034,0.25,0.5431034482758621,0.1724137931034483,0.603448275862069,0.6206896551724138,-0.3706896551724138,-0.5517241379310345,-0.5086206896551724,0.3448275862068966,0.29310344827586204,0.3017241379310345]
]